import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import Layout from "../components/layout"

export default (props) => (  
  <Layout>
  <Helmet bodyAttributes={{class:'home'}}/>   
    <div>
      <div className="section">
        <section className="hero">
          <div className="grey-thing"></div>
          <div className="wrapper">
            <div className="content">
              <div className="square"></div>
              <Img 
                fluid={props.data.meHome.childImageSharp.fluid}
                className="me"
                style={{
                  position: `fixed`
                }}
                placeholderClassName="me"
              />
              <h1 className="hero-header">Do <a href="/work">Work</a>.</h1>
              <p>I'm a multi-disciplinary product designer working on special products at <a href="https://proxy.com" target="_blank" rel="noopener noreferrer">Proxy</a>, where we're building better tools to help individuals hold onto and maintain their personal data.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

// Get the Site Meta Data
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    meHome: file(relativePath: {eq: "jeffrey-jorgensen@2x.png"}) {
      childImageSharp {
        fluid(maxWidth:1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`